<div class="contact container">
    <h1>Contact Us</h1>
    <p>If you have any questions, or would like to volunteer, please fill out the form below
      and we will respond as soon as possible.
    </p>
</div>
<div *ngIf="error && submitted && contactForm.valid" class="container">
    <div class="message error" #message tabindex="0">
        <i class="material-icons">error</i>
        <p>{{error}}</p>
    </div>
</div>
<div *ngIf="!error && submitted && contactForm.valid || submitted && honeypot" class="container">
    <div class="message success" #message tabindex="0">
        <i class="material-icons">check_circle</i>
        <p>Success! Your message has been sent!</p>
    </div>
    <a class="return" routerLink="/">Return to home</a>
</div>
<div class="container contact-us" *ngIf="!submitted || (submitted && error)">
    <form class="mat-elevation-z8" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <mat-form-field>
            <mat-label>Name:</mat-label>
            <input name="name" matInput placeholder="First and last name" formControlName="name" required>
            <mat-error *ngIf="contactForm.get('name').invalid">{{nameValidationMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Message:</mat-label>
            <textarea name="message" matInput placeholder="How can we help you?" formControlName="message" required></textarea>
            <mat-error *ngIf="contactForm.get('message').invalid">{{messageValidationMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Phone:</mat-label>
            <input name="phone" matInput placeholder="Phone number" formControlName="phone">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Email:</mat-label>
            <input matInput placeholder="Enter your email" formControlName="email" required>
            <mat-error *ngIf="contactForm.get('email').invalid">{{emailValidationMessage()}}</mat-error>
        </mat-form-field>
        <mat-form-field class="secondary-email" aria-hidden="true">
            <mat-label>Email:</mat-label>
            <input name="secondary" matInput placeholder="Enter your email" formControlName="secondary">
        </mat-form-field>
        <button mat-raised-button type="submit" color="primary">Submit</button>
    </form>
</div>
