<div class="about-hero">
    <div class="container">
        <h1>About Curtie Cares</h1>
    </div>
</div>
<div class="container">
    <img class="curt-hospital" src="/assets/curtie_hospital.jpg" alt="Photo of Curt Harrison in a wheelchair during his recovery from a motorcycle accident.">
    <p>On the night of July 2, 2018, Curt "Curtie" Harrison was out riding his motorcyle when a deer jumped out in front of him. He spent 13 days in the hospital, and had a long recovery. It was during this recovery period that Curtie realized he wanted to help people in the same situation he was in. He decided to start his new non-profit organization, <span class="cc">Curtie Cares</span>.</p>
    <p>Through <span class="cc">Curtie Cares</span>, Curtie offers help and hope to the homebound by giving them rides, running errands for them, or really whatever they need - at <strong>NO COST</strong> to the participants. Through his own experience, Curtie has learned that people don't always ask for help when they need it&mdash;including himself.</p>
    <p>Curtie hopes that through <span class="cc">Curtie Cares</span>, those who are homebound or disabled will find they are not alone, and will ask for the help they need and deserve.</p>
</div>
<div class="help container">
    <h2>How you can help</h2>
    <p>Curtie and his wife, Julie Rae, are providing rides when they have time between their full-time jobs. They now have a dedicated vehicle for <span class="cc">Curtie Cares</span>, which means more volunteers will be able to take people where they want to go (depending on availability).</p>
    <p>Your generous donations go to cover gasoline and the expense of travel.</p>
    <div class="donate-link-wrapper">
        <a class="donate-link" routerLink="/donate"><img src="/assets/heart-white.png" alt="Heart icon">Donate Today!</a>
    </div>
</div>
