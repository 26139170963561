<div class="donate-heading">
    <div class="container">
        <h1>Donations</h1>

        <div class="donation-options">
            <img class="curt-van" src="/assets/car-hero-960px.jpg" alt="Photo of Curt Harrison standing next to his Yukon with Curtie Cares printed on the door.">


            <p>Your donations will help with the cost of gasoline and travel expenses.</p>

            <p><span class="cc">Curtie Cares</span> is a 501(c)3 non profit organization. Donations by check can be mailed to: Curtie Cares, PO Box 135, Bloomer, WI 54724. A tax-deductible receipt will be mailed back to you.</p>

            <h3>Donate Online</h3>
            <div class="donate-online">
                <div class="grid-item">
                    <a href="https://www.gofundme.com/curtie-cares-nonprofit-startup-fee" target="_blank"><img src="../../assets/GoFundMe-Logo-1.jpg" alt="Go Fund Me"></a>
                </div>
                <div class="grid-item">
                    <a href="https://Paypal.me/curtiecares" target="_blank"><img src="../../assets/paypal.png" alt="Paypal"></a>
                </div>
                <div class="grid-item">
                  <a href="https://venmo.com/Curtis-Harrison-22" target="_blank"><img src="../../assets/venmo.png" alt="Venmo"></a>
                </div>
            </div>
        </div>
    </div>
</div>
