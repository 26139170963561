import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutModule } from './about/about.module';
import { FaqModule } from './faq/faq.module';
import { ContactModule } from './contact/contact.module';
import { HomeModule } from './home/home.module';
import { DonateModule } from './donate/donate.module';

const routes: Routes = [
    {
        path: 'about',
        loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
    },
    {
        path: 'faq',
        loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
    },
    {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
    },
    {
        path: 'donate',
        loadChildren: () => import('./donate/donate.module').then(m => m.DonateModule)
    },
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    }
];

@NgModule({
  imports: [
      AboutModule,
      FaqModule,
      ContactModule,
      HomeModule,
      DonateModule,
      RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
