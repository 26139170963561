<div class="home-hero">
    <img src="/assets/sparkles.svg" alt="Bringing hope to the homebound.">
</div>
<img src="/assets/car-hero-600px.jpg" alt="Vehicle with Curtie Cares logo on the side." class="mobile-hero">
<img src="/assets/car-hero-1280px.jpg" alt="Vehicle with Curtie Cares logo on the side." class="mobile-hero tablet">
<div class="home-heading">
    <img src="/assets/home-heading.svg" alt="Bringing hope to the homebound.">
</div>
<div class="phone">
    <h1>Free transportation to those in need!
    <span>Call for a ride: <a href="tel:715-568-2211">715-568-2211</a></span></h1>
</div>
<div class="donations">
    <a class="donate-link" routerLink="donate"><img src="/assets/heart-white.png" alt="Heart icon">Donations Welcome!</a>
</div>
<div class="container sponsors-container">
    <h2 class="sponsors-heading">Our Sponsors</h2>
    <div class="sponsors">
        <a href="https://www.festfoods.com/" target="_blank"><img src="/assets/festival-foods.png" alt="Festival Foods logo"></a>
        <a href="https://www.kwiktrip.com/" target="_blank"><img src="/assets/kwik-trip.svg" alt="Kwik Trip logo"></a>
        <a href="https://www.facebook.com/farmhousebloomerwi/?rf=812321805547369" target="_blank"><img src="/assets/Farmhouse.png" alt="Farmhouse Cafe logo"></a>
        <a href="https://www.facebook.com/wrapguys.rk/" target="_blank"><img src="/assets/the-wrap-guys.jpg" alt="The Wrap Guys logo" class="wrap-guys"></a>
        <a href="http://emilymikl.com/" target="_blank"><img src="/assets/emily-mikl-logo.svg" alt="Emily Mikl logo"></a>
    </div>
</div>
<div class="midsection">
    <div class="tile">
        <div class="tile-content">
            <span class="heart-and-hands"><img src="/assets/heart-and-hands.svg" alt="Hands holding heart"></span>
            <h3>We Serve Everyone</h3>
            <ul>
                <li>Appointments</li>
                <li>Errands</li>
                <li>Dinner out</li>
                <li>Just for a drive</li>
            </ul>
        </div>
    </div>
    <div class="tile">
        <div class="tile-content">
            <span class="wheelchair"><img src="/assets/van-icon.svg" alt="Wheelchair icon"></span>
            <h3>SPACIOUS VEHICLE</h3>
            <p>We have room for your walker or compact foldable wheelchair.</p>
            <p><strong>Donations helped us purchase a dedicated vehicle to better serve you!</strong></p>
        </div>
    </div>
</div>
<div class="founder-background">
    <div class="founder">
        <div class="photo-div">
          <img src="/assets/curtie.jpg" alt="Photo of Curtie Harrison, founder of Curtie Cares, Inc.">
        </div>
        <div class="bio">
            <h3>Meet the Founder</h3>
            <p>After a motorcycle accident in July 2018, Curt "Curtie" Harrison realized the need of help when someone is homebound. He started <span class="cc">Curtie Cares</span> to offer hope to the homebound in the form of rides and errand services.</p>
            <p>It's not easy to ask for help. Curtie hopes that through <span class="cc">Curtie Cares</span>, those who are homebound or disabled will find they are not alone, and will ask for the help they need and deserve.</p>
            <a class="cta-link" routerLink="/about">Learn More<span class="screen-reader">about Curtie Cares</span></a>
        </div>
    </div>
</div>
