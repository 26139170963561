import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Contact } from './contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
    errorMessage: string;
    data: any;

    httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
    };

    constructor(private http: HttpClient) {}

    getData(): Observable<any> {
        return this.http.get('/api/contact_form.php');
    }

    contactForm(formdata: Contact): Observable<Contact> {
        return this.http.post<Contact>('/api/contact_form.php',
        formdata, this.httpOptions).pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred: ', error.error.message);
        } else {
            console.error(`Response was  + ${error.status},` + ` body was:  + ${error.error}`);
        }

        this.errorMessage = 'Oops! Something went wrong. Please try again later.';

        return throwError(this.errorMessage);
    }
}
