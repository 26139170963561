import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ContactService } from './contact.service';
import { Contact } from './contact';
import { HttpErrorResponse } from '@angular/common/http';

export class MyErrorStateMatcher implements ErrorStateMatcher {
      isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
            const isSubmitted = form && form.submitted;
            return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
      }
}

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.less']
})
export class ContactComponent implements OnInit {
  @ViewChild('message') messageDiv: ElementRef;

    contactForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        secondary: new FormControl(''),
        name: new FormControl('', Validators.required),
        message: new FormControl('', Validators.required),
        phone: new FormControl()
    });

    submitted = false;
    error: HttpErrorResponse;
    data: any;
    honeypot = false;

  constructor(private contactService: ContactService) { }

    emailValidationMessage() {
        return this.contactForm.get('email').hasError('required') ? 'Please enter an email' :
        this.contactForm.get('email').hasError('email') ? 'Not a valid email' : '';
    }

    nameValidationMessage() {
        return this.contactForm.get('name').hasError('required') ? 'Please enter your name' : '';
    }

    messageValidationMessage() {
        return this.contactForm.get('message').hasError('required') ? 'Please enter a message' : '';
    }

    ngOnInit() {}

    onSubmit() {
        if (this.contactForm.valid && this.contactForm.get('secondary').value) {
            this.submitted = true;
            this.honeypot = true;
        }
        if (this.contactForm.valid && !this.honeypot) {
            this.submitted = true;
            return this.contactService.contactForm(this.contactForm.value).subscribe((res: Contact) => {
                this.data = res;
                this.messageDiv.nativeElement.focus();
            }, (error: HttpErrorResponse) => {
                this.error = error;
                this.messageDiv.nativeElement.focus();
            });
        }
    }

}
