<div class="faq-heading">
    <div class="container">
        <h1>Frequently Asked Questions</h1>
    </div>
</div>
<div class="container faqs">
    <h2>What kind of a vehicle do you use for the transportation?</h2>
    <p>With the help of generous donations, <span class="cc">Curtie Cares</span> was able to purchase a 2016 Kia Sorento. Patrons need to be able to step up into it. We do have a step stool to assist.</p>
    
    <h2>Are rides through Curtie Cares just for doctor appointments?</h2>
    <p>No! It's anything from doctor appointments to getting ice cream.</p>

    <h2>Is there a cost?</h2>
    <p>There is no cost, but donations are welcome!</p>

    <h2>Is it just for the elderly or disabled?</h2>
    <p><span class="cc">Curtie Cares</span> is open to anyone in need of a ride.</p>

    <h2>What area do you serve?</h2>
    <p>We serve the Chippewa Valley area.</p>
</div>
