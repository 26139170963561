<header>
    <div class="header-container" fxLayout="row-reverse wrap" fxLayout.gt-sm="row wrap" fxLayoutAlign="flex-start center">
        <a class="home-logo" routerLink="/"><img src="/assets/cc_logo.png" alt="Curtie Cares logo"></a>
        <nav fxLayoutAlign="center" fxShow fxHide.gt-sm class="mobile">
            <button mat-icon-button [matMenuTriggerFor]="appMenu">
                <i class="material-icons">menu</i>
            </button>
            <mat-menu #appMenu="matMenu">
                <a routerLink="about" mat-menu-item>About</a>
                <a routerLink="faq" mat-menu-item>FAQ</a>
                <a routerLink="contact" mat-menu-item>Contact</a>
                <a routerLink="donate" mat-menu-item fxHide.gt-sm>Donate</a>
            </mat-menu>
        </nav>
        <nav class="desktop" fxHide fxShow.gt-sm>
            <ul fxLayout="row wrap" fxLayoutAlign="center">
                <li><a routerLink="about" mat-menu-item>About</a></li>
                <li><a routerLink="faq" mat-menu-item>FAQ</a></li>
                <li><a routerLink="contact" mat-menu-item>Contact</a></li>
            </ul>
        </nav>
        <a class="facebook" href="https://www.facebook.com/Curtie-Cares-Bringing-hope-to-the-homebound-378757329329446/" target="_blank"><img src="/assets/facebook_circle.png" alt="Go to our Facebook page"></a>
        <a class="donate-link" fxHide fxShow.gt-sm routerLink="donate"><img src="/assets/heart.svg" alt="Heart icon">Donate Now!</a>
    </div>
</header>

