import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DonateComponent } from './donate.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    DonateComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: 'donate', component: DonateComponent, pathMatch: 'full'}
    ])
  ],
  exports: [RouterModule]
})
export class DonateModule { }
